import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import './ArticleItem.css'
import BannerList from "../Banners/BannerList";
import PostList from "./PostList";
import { Context } from "../../../index";
import OneSignal from "react-onesignal";


const ArticleItem = ({ post, title, id, articleSeoName }) => {
    const { storeblog, postStore } = useContext(Context);
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(post, 'text/html');
    const imgTags = parsedHtml.getElementsByTagName('img');

    let photo = '';
    for (let i = 0; i < imgTags.length; i++) {
        if (!imgTags[i].closest('.article_banner_box')) {
            photo = imgTags[i].getAttribute('src');
            break;
        }
    }

    const [isMore, setIsMore] = useState(false);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        storeblog.setSelectedCategory('');

        async function fetchBackButtonLink() {
            try {
                await storeblog.GetBackBtnLink();
                // console.log(JSON.stringify(storeblog.backBtnLink), 'HELLO back btn'); // Спробуйте серіалізувати для кращого перегляду
                setBackButtonLink(JSON.stringify(storeblog.backBtnLink));
            } catch (error) {
                console.error('Error fetching back button link:', error);
            }
        }
        fetchBackButtonLink();


        postStore.getSignalToken();
        const runOneSignal = () => {
            setTimeout(() => {
                if (postStore.signalToken[0].signal) {
                    OneSignal.init({appId: `${postStore.signalToken[0].signal}`}).then(() => {
                        setInitialized(true);
                        OneSignal.Slidedown.promptPush();
                    });
                } else {
                    runOneSignal();
                }
            }, 1000);
        };
        runOneSignal();

    }, [])


    const setBackButtonLink = (data) => {
        let bbdata = JSON.parse(data)

        if(bbdata[0].backBtnLinkStatus){
            window.history.pushState({ redirected: false }, '', window.location.href);
            const handleBackButton = () => {
                window.location.href = bbdata[0].backBtnLinkDB;
            }
            window.addEventListener('popstate', handleBackButton);
        }else{
            return false
        }
    }


    return (
        <div className='px-9'>

            <div className='article_box'>
                {post !== ''
                    ? (
                        isMore
                            ? <div dangerouslySetInnerHTML={{ __html: post }} className='article_body' />
                            :
                            <div className='article_body'>
                                <h2>
                                    <img src={photo} alt="photo" />
                                </h2>
                                <h2>{title}</h2>
                                <button className='article_more_btn' onClick={() => setIsMore(!isMore)}>Daha fazlasını göster</button>
                            </div>
                    )
                    : <div className='article_body'>
                        <div className='loader_box'>
                            <div className="loader"></div>
                        </div>
                    </div>
                }
                <div className='banners_box'>
                    <BannerList id={id} articleSeoName={articleSeoName}/>
                </div>
            </div>
            <div>
                <hr className='my-6 w-[90%] mx-auto' />
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='row mt-4 row-cols-1 row-cols-md-2 row-cols-lg-4 gy-4 gy-lg-0'>
                        {/*<PostList />*/}
                        {/*<BannerList />*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(ArticleItem);